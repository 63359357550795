.business-links {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns in the first row */
    gap: 80px; /* Space between items */
    padding: 20px;
    justify-items: center;
  }
  
  @media (max-width: 768px) {
    .business-links {
      padding: 10px;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile view */
    }
    .business-logo {
      max-width: 114px; /* Prevents it from becoming too large */
      max-height: 76px; /* Prevents it from becoming too large */
    }
  }
  
  .business-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  
  .business-logo {
    width: 180px; /* Fixed width */
    height: 120px; /* 3:2 aspect ratio */
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .business-link:hover .business-logo {
    transform: scale(1.05);
  }
  

  