.phone-link-container {
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100vh; /* Optional: Sets the height of the container */
  }
  
  .phone-link {
    font-size: 1.2rem; /* Adjusts the text size */
    color: black; /* Sets the text color to black */
    text-decoration: none; /* Removes underline */
  }
  
  .phone-link:hover {
    text-decoration: underline; /* Adds underline on hover */
  }
  