/* Block Container */
.block-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: center; /* Center items horizontally */
  gap: 50px; /* Space between blocks */
  padding: 16px;
}

/* Block Item */
.block-item {
  display: flex;
  flex-direction: column; /* Ensure the content stacks vertically */
  justify-content: space-between; /* Space content evenly */
  align-items: center;
  width: 100%; /* Make blocks side-by-side */
  max-width: 1000px;
  box-sizing: border-box;
  padding: 16px; /* Padding inside each block */
  border-radius: 12px;
}

/* Block Image */
.block-image {
  width: 100%; /* Full width of the container */
  height: 500px; /* Fixed height for uniform size */
  object-fit: cover; /* Ensures the image maintains its aspect ratio and fills the height */
  border-radius: 12px; /* Rounded corners */
  cursor: pointer;
  overflow: hidden; /* Ensures content stays within the bounds */
}

/* Block Content */
.block-content {
  text-align: center;
}

.block-title {
  font-size: 1.5rem;
  margin: 16px 0 8px;
  color: #333;
}

/* Block Text */
.block-text {
  font-size: 1rem;
  margin: 8px 0;
  color: #555;
  flex-grow: 1; /* Allow text to grow and fill available space */
}

/* Block Button */
.block-button {
  margin-top: 10px; /* Push the button to the bottom */
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: var(--marketplaceColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.block-button:hover {
  background-color: var(--marketplaceColorDark);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .block-container {
    flex-direction: column; /* Stack items vertically */
    gap: 16px;
  }

  .block-item {
    width: 90%; /* Take most of the screen width */
    max-width: none;
  }

  .block-image {
    height: 200px; /* Smaller image height for mobile */
  }

  .block-title {
    font-size: 1.25rem;
  }

  .block-text {
    font-size: 0.95rem;
  }

  .block-button {
    padding: 10px 20px;
  }
}
