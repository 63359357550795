
.time-picker-container {
  margin-left: 30px;
}
/* Existing DatePicker Styles */
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

/* Add custom placeholder color for DatePicker input */
.react-datepicker__input-container input::placeholder {
  color: black;
  opacity: 1; /* Ensure the placeholder displays at full opacity */
}

/* Compatibility for older browsers */
.react-datepicker__input-container input::-webkit-input-placeholder {
  color: black;
}

.react-datepicker__input-container input:-ms-input-placeholder {
  color: black;
}

.react-datepicker__input-container input::-ms-input-placeholder {
  color: black;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker-popper {
  z-index: 1;
  line-height: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  fill: #fff;
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.94rem; /* 0.8rem * 1.18 */
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px; /* navigation-button-size */
  width: 32px; /* navigation-button-size */
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}

.react-datepicker__navigation-icon::before {
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  width: 9px;
  border-style: solid;
  border-color: #ccc;
  border-width: 3px 3px 0 0;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}
.react-datepicker__aria-live {
  display: none;
}

.react-datepicker__year,
.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-text,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker-time__caption,
.react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: white;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

/* Custom Search Component Styles */
.search-section {
display: flex;
align-items: flex-start; /* Align items to the top left */
justify-content: flex-start; /* Align items to the left horizontally */
flex-direction: column;
z-index: 2;
margin-top: 10px;
margin-left: 30px;
}

.search-section .search-row {
display: flex;
align-items: center;
gap: 12px; /* Space between inputs */
}

.search-category {
width: 180px;
}

.search-button {
display: flex;
align-items: center;
padding: 8px;
cursor: pointer;
margin-left: 8px;
background-color: white;
border: none; /* Removes the border */
}

/* Mobile layout: make items display vertically */
@media (max-width: 600px) {
  .search-section {
      margin-left: 0px;
      flex-direction: column; /* Stack elements vertically */
      width: 100%; /* Full width for mobile view */
  }

  .search-section .search-row {
      flex-direction: column; /* Stack each input vertically */
      width: 100%;
      gap: 20px; /* Space between the stacked elements */
  }

  .search-section .search-category, .search-button {
      width: 90%; /* Full width for mobile */
  }

  .search-button {
    margin-top: 12px;
    display: block; /* Ensure the button behaves like a block element */
    margin-left: auto;
    margin-right: auto;
    padding: 8px; /* Adjust padding for a smaller look */
    width: 35px;  /* Make it square */
    height: 35px; /* Make it square */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-button svg {
    font-size: 1.5rem; /* Adjust the size of the search icon */
}
}
